<template>
  <div>
    <div class="no-permission-page">
      <div class="no-data-content">
        <img src="@/assets/img/common/no_jurisdiction.svg" alt="暂无数据" />
        <p class="no-data-hint">
          <span>当前账号无权限，请联系管理员</span>
          <!-- <br />
          <span>请联系企业微信管理员开通会话存档权限</span> -->
        </p>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less">
.no-permission-page {
  margin-top: 1px;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  .no-data-content {
    margin-top: -65px;
    img {
      display: block;
      width: 110px;
      height: 118px;
      margin: 0 auto;
    }
    .no-data-hint {
      margin-top: 13px;
      font-size: 14px;
      line-height: 20px;
      color: #909399;
      text-align: center;
    }
  }
}
</style>
